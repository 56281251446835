<template>
  <MainLayout fluid>
      <UsersTable />
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import User from '@/resources/User'

const UsersTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(User)]
}

export default {
  components: { MainLayout, UsersTable },
  metaInfo() {
    return {
      title: this.$t('users.title'),
    }
  },
};
</script>